import { useCandidateStore } from "@/store/candidate";
import { useApplicationStore } from "@/store/application";
import { useJobStore } from "@/store/job";
export default defineNuxtRouteMiddleware(async (to, _) => {
  const { showProfile, selectedCandidateId } = storeToRefs(useCandidateStore());
  const { selectedApplication } = storeToRefs(useApplicationStore());
  const jobStore = useJobStore();
  const { recommendedCandidates } = storeToRefs(jobStore);

  if (showProfile.value) {
    showProfile.value = false;
    selectedCandidateId.value = null;
  }
  if (selectedApplication.value) selectedApplication.value = null;

  if (recommendedCandidates.value) jobStore.clearRecommendedCandidates();
});
